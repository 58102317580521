(function ($) {
    "use strict";

    // Initiate the wowjs
    new WOW().init();

    $('.back-to-top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1500, 'easeInOutExpo');
        return false;
    });


    // Sticky Navbar
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }
        if ($(this).scrollTop() > 90) {
            $('.top-bar').addClass('top-sticky');
        } else {
            $('.top-bar').removeClass('top-sticky');
        }
    });

    $(document).on('click', '[data-spyjs="scroll"]', function (evt) {
        evt.preventDefault();
        console.log($(this).data('target'));
        var offset = $($(this).data('target')).offset();
        console.log(offset);
        if (offset) {
            $('html, body').animate({
                scrollTop: offset.top - 80
            }, 1500, 'easeInOutExpo');
        }
        return false
    });

})(jQuery);
