import Vue from 'vue';
// import ElementUI from 'element-ui/lib/element-ui.common';
import lang from 'element-ui/lib/locale/lang/it'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css';
import Image from 'element-ui/lib/image'
import Row from 'element-ui/lib/row'
import Col from 'element-ui/lib/col'
locale.use(lang)

Vue.component(Image.name, Image);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
